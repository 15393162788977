import * as React from "react";
import { Helmet } from "react-helmet";

import { useConfig } from "gatsby-theme-advanced";
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import ImageListItemBar from "@mui/material/ImageListItemBar"
import IconButton from "@mui/material/IconButton"
import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'

import InfoIcon from '@mui/icons-material/Info';

import Layout from "../layouts";

import "./photos.css"

const AboutPage = (): JSX.Element => {
  const config = useConfig();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [image, setImage] = React.useState("");
  const [caption, setCaption] = React.useState("");


  const images = [[200, 300], [300, 400], [500, 400], [1200, 800], [350, 600], [600, 600]]

  return (
    <Layout>
      <Container className="photo-container">
        <Helmet title={`Photos | ${config.website.title}`} />

        <Modal
          open={modalOpen}
          onClose={()=>setModalOpen(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Card id="modalCard" elevation={4}>
            <CardMedia
              component="img"
              height="100%"
              image={image}
            />
            <CardContent>
            <Typography id="modal-title" variant="h6">
              Text in a modal
            </Typography>
            <Typography id="modal-description" sx={{ mt: 2 }}>
              {caption}
            </Typography>
            </CardContent>
          </Card>
        </Modal>

        <Stack direction="column" spacing={2}>
        <h1>My Photos</h1>
        <h6>This page is a placeholder.</h6>
        
        <ImageList variant="masonry" cols={3} gap={8}>
          {images.map((dims) => (
            <ImageListItem key={dims[0]}>
              <img
                src={`https://picsum.photos/${dims[0]!.toString()}/${dims[1]!.toString()}`}
                alt=""
                loading="lazy"
              />
              <ImageListItemBar
                title={"Hello"}
                subtitle={"There"}
                actionIcon={
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label={`info about this photo.`}
                    onClick={()=>{
                      setImage(`https://picsum.photos/${dims[0]!.toString()}/${dims[1]!.toString()}`);
                      setCaption("Filler");
                      setModalOpen(true);
                    }}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
        </Stack>


      </Container>
    </Layout>
  );
};

export default AboutPage;
